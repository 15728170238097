/* image text */

.panel-image-text .grid-wrapper-grid-area {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    -ms-grid-columns: 1fr 1fr 1fr 1fr !important;
    grid-template-rows: auto;
    grid-auto-flow: row;
    -ms-grid-rows: auto;
    grid-auto-rows: auto;
    grid-row-gap: 0;
    width: 100%;
    grid-gap: 0;
    .grid-area {
    position: relative;
    }
    img {
      margin: 0 !important;
    }
  }
  .grid-area-image {
  grid-area: 1 / 1 / span 1 / span 2;
  @include tablet-portrait {
    grid-area: 1 / 1 / span 1 / span 4;
  }
  }
  .grid-area-content {
  grid-area: 1 / 3 / span 1 / span 2;
  @include tablet-portrait {
    grid-area: 2 / 1 / span 1 / span 4;
  }
  }
  
  .panel-image-text .grid-wrapper-grid-area.toggle-grid-area {
    .grid-area-image {
    grid-area: 1 / 3 / span 1 / span 2;
    @include tablet-portrait {
      grid-area: 1 / 1 / span 1 / span 4;
    }
    }
    .grid-area-content {
    grid-area: 1 / 1 / span 1 / span 2;
    @include tablet-portrait {
      grid-area: 2 / 1 / span 1 / span 4;
    }
    }
  }
  
  .panel-image-text img {
    margin: 0;
  }
  
  .panel-image-text {
    .grid-wrapper-grid-area {
      grid-gap: 0;
      .grid-area {
        margin: 0;
        @include phone-portrait {
          margin: 0 !important;
        }
      }
    }
    .content-image-text-image {
        padding-top: 100%;
        height: 100%;
        overflow: hidden;
        background-image: url('../images/images-portraits-1000x1000/medallion-2023-grace-nelson-nursing-1000x1000.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        img {
        display: none;
        }
    }
    .content-image-text {
        padding: 4rem;
        height: 100%;
        background: lighten($gray-light, 10%);
        h6, h4, p, p em, p strong, blockquote, cite {
            color: $black;
        }
        p {
            font-family: 'Open Sans', sans-serif;
            font-size: 1.125rem;
        }
        blockquote, cite {
            text-align: left;
            display: none;
        }
        cite {
            text-transform: uppercase;
        }
    }
}
  
.panel-image-text.bg-maroon,
.panel-image-text.bg-gray,
.panel-image-text.bg-gray-dark,
.panel-image-text.bg-patina,
.panel-image-text.bg-patina-dark {
.content-image-text {
        h6, h4, p, p em, p strong, blockquote, cite {
            color: $white !important;
        }
    }
}

.panel-image-text.bg-maroon .content-image-text {
    background: $maroon !important;
}
.panel-image-text.bg-gray .content-image-text {
    background: $gray !important;
}
.panel-image-text.bg-gray-dark .content-image-text {
    background: $gray-dark !important;
}
.panel-image-text .content-image-text-image img::before {
    height: 100%;
}
  

/* image-text-blockquote */

.panel-image-text.panel-image-text-blockquote .content-image-text-image {
    background-image: url('../images/images-portraits-1000x1000/medallion-2023-jonathan-larbi-arrupe-1000x1000.jpg');
}
.panel-image-text.panel-image-text-blockquote .content-image-text {
    h6, p {
        display: none;
    }
    h4 {
        font-size: 1.25rem;
        line-height: calc(var(--baseline) * 1.75 * 1rem);
        text-transform: uppercase;
    }
    blockquote, cite {
        display: block;
    }
    blockquote {
        margin: 1.5rem 0;
        padding: 0;
        width: 100%;
        font-size: 1.5rem;
        line-height: calc(var(--baseline) * 2.5 * 1rem);
        text-align: left;
        border: none;
    }
    cite {
        font-family: "Open Sans", sans-serif;
        font-size: .85rem;
        text-transform: uppercase;
        line-height: calc(var(--baseline) * 1.5 * 1rem);
        margin-bottom: 1.5rem;
    }
    .bg-maroon button {
        background-color: $white !important;
    }
}

.panel-image-text.panel-image-text-blockquote.grid-area-content-only {
    .grid-area.grid-area-image {
        display: none;
    }
    .grid-area-content {
        grid-area: 1 / 1 / span 1 / span 4;
        @include tablet-portrait {
          grid-area: 2 / 1 / span 1 / span 4;
        }
        .content-image-text {
            background: $white;
        }
        h6, h4, blockquote, cite, a {
            width: 100%;
            text-align: center;
        }
        blockquote {
            margin-left: auto;
            margin-right: auto;
            width: 80%;
        }
        button {
            margin: 0 auto;
        }
    }
}