/* header - update - 051624 */
/* move to main-navigation.scss */

ul#directory-nav[role=menubar] li:last-of-type button {
    color: $black !important;
    background-color: $gold;
    &:hover {
        background-color: lighten($gold, 10%) !important;
    }
}
header {
    #header-white {
        #site-brand-wrapper {
        padding: 1.125rem 1rem .75rem;
        background: $white !important;
        border-bottom: solid .25rem $gold;
        box-shadow: 0 0 .5rem $bg-overlay;
        #brand {
                display: block;
                float: left !important; /* template override */
                width: auto !important;
                img {
                    opacity: 1;
                }
                @include phone-portrait {
                    display: none;
                }
            }
            #loyola-university-chicago {
                display: none;
                @include phone-portrait {
                    display: block;
                }
            }
            #site-name {
                display: inline;
                width: auto;
                font-size : 1.75rem;
                @include phone-portrait {
                    display: block;
                    width: 80%;
                }
                span.subsite-label {
                    display: block;
                    position: relative;
                    top: -.35rem;
                    margin: 0 0 -.125rem 1rem;
                    padding: 0 0 0 1rem;
                    width: auto;
                    height: auto;
                    font-family: 'Antenna Condensed Light';
                    font-size: .75rem !important;
                    color: $black !important;
                    letter-spacing: 0;
                    word-spacing: .075rem;
                    line-height: 1.25;
                    text-transform: uppercase;
                    border-left: solid 1px $black;
                    @include phone-landscape {
                        top: 0;
                        margin: .25rem 0 .35rem;
                        padding: 0;
                        letter-spacing: 0rem;
                        border-left: none;
                    }
                }
                > a {
                    display: block;
                    position: relative;
                    top: -.35rem;
                    margin: .125rem 0 0 1rem;
                    padding: 0 0 0 1rem;
                    width: auto;
                    height: auto;
                    color: $black !important;
                    letter-spacing: -.075rem;
                    line-height: 1.25;
                    border-left: solid 1px $black;
                    @include phone-landscape {
                        margin: 0;
                        padding: 0;
                        font-size: 1.5rem;
                        border-left: 0;
                    }
                }
            }
        }
    }
}

header #loyola-university-chicago a {
    color: $black;
}

/* framework - aside */
#social-icon-bar {
    top: 60% !important;
    left: -.5rem;
    margin: 0 0 0 1.5rem;
    background: none !important;
}
#social-icon-bar a {
    margin: .75rem 0 !important;
    padding: .5rem .6rem !important;
    font-size: 1.5rem;
    border: solid .125rem $white !important;
    border-radius: 50% !important;
    background: $gray-dark !important;
    transition: all .3s;
}

/* framework */
footer {
    padding: 1rem 2rem;
    height: auto;
    border-top: solid .25rem $gold;
    @include phone-landscape {
        span#brand {
            display: block;
            margin: 1rem 30%;
            width: 40%;
            max-width: 40%;
            a {
                display: inline-block;
                margin: 0 auto;
                width: 100%;
                max-width: 100%;
            }
            img {
                margin: .5rem auto;
                width: 100%;
                height: auto;
            }
        }
    }
    @include phone-portrait {
        span#brand {
            margin: 1rem auto;
            width: 100%;
            max-width: 100%;
            @include phone-portrait {
                margin: 0 auto;
                img {
                    margin: 1rem 15%;
                    width: 70%;
                } 
            }
        }
    }
    #footer-icon-bar {
        margin: .5rem auto 1rem;
        a {
            margin: 0 .25rem;
            i  {
                font-size: 1.75rem;
            }
        }
        @include phone-portrait {
            margin: .5rem auto .5rem;
            width: fit-content;
        }
    }
}
#footercard, #footercard a, #footercard #street-address,
#footercard #locality, #footercard .region, #footercard .postal-code {
    font-size: .85rem;
}