/* tabs ensemble */

/* image display */

.tabs-ensemble .panel-image-text .content-image-text-image {
    padding: 0;
    background-image: none;
    background: $gray-extra-dark;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabs-ensemble .panel-image-text .content-image-text-image img {
    display: block;
    margin: auto auto;
}

/* panel background color - default - typography */

.tabs-ensemble .tabs .tab-panel .panel-image-text .content-image-text {
    padding: 2rem;
    background: $gray-extra-light;
    h4 {
        line-height: calc(var(--baseline) * 1.65 * 1rem);
        text-transform: none;
    }
    cite {
        font-size: .85rem;
    }
}

.tabs-ensemble .tabs .tab-panel .panel-image-text.panel-image-text-blockquote .content-image-text {
    h4 {
        font-size: 1.125rem !important;
        text-transform: uppercase;
    }
}

/* panel image text - blockquote only */

.tabs-ensemble .panel-image-text.panel-image-text-blockquote.grid-area-content-only .content-image-text {
    padding: 0;
    background: $white;
}

.tabs-ensemble .tabs .tab-panel .panel-image-text-blockquote.grid-area-content-only .content-image-text h4 {
    font-size: 1.75rem !important;
}

.tabs-ensemble .panel-image-text.panel-image-text-blockquote .content-image-text blockquote {
    @include phone-landscape {
        font-size: 1.25rem !important;
        line-height: calc(var(--baseline) * 1.75 * 1rem);
    }
}

.tabs-ensemble .panel-image-text.panel-image-text-blockquote .content-image-text cite {
    @include phone-landscape { 
        font-size: .75rem !important;
        line-height: calc(var(--baseline) * 1.125 * 1rem);
    }
}

/* content - buttons */

.tabs-ensemble .panel-image-text.panel-image-text-blockquote .bg-maroon button {
    background: $gold !important;
}

/* tabs list - tabs - buttons */

.tabs-ensemble .tabs {
    display: flex;
    flex-flow: column;
}

.tabs-ensemble .tabs.reverse-order {
    display: flex;

}

.tabs-ensemble .tabs .tablist-container {
    display: flex;
    height: 9rem;
    align-items: center;
}

.tabs-ensemble .tabs .tab-list {
    margin: 1.5rem auto 1.25rem auto;
    display: inline-block;
}

.tabs-ensemble .tabs .tab-list .tab-button {
    margin: 1.25rem;
    padding: 0;
    width: 5.5rem;
    height: 5.5rem;
    border: none;
    border-radius: 100% !important;
    transition: 500ms all ease;
    filter: saturate(0);
    opacity: 0.5;
    @include phone-landscape {
        display: inline;
        margin: 0 .5rem;
        width: 3.5rem;
        height: 3.5rem;
    }
    @include phone-portrait {
        display: inline;
        margin: 0 .35rem;
        width: 2.5rem;
        height: 2.5rem;
    }
}

.tabs-ensemble .tabs .tab-list .tab-button[aria-selected="true"] {
    scale: 1.25;
    outline: solid 5px $maroon;
    filter: saturate(1);
    opacity: 1;
    @include phone-portrait {
        scale: 1.125;
    }
}

.tabs-ensemble .tabs .tab-list img {
    border-radius: 100% !important;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    pointer-events: none;
}

.tabs-ensemble .tabs .tab-panel {
    background-color: transparent;
    @include phone-landscape {
        padding: 0;
    }
}

/* overrides tabs default - button */

.tabs-ensemble .grid-wrapper-min-max .grid-item .tabs .tab-panel button {
    background: $maroon;
    border-radius: 0.33rem !important;
}

/* specific - content - bg-maroon - button */

.tabs-ensemble .grid-wrapper-min-max .grid-item .tabs .tab-panel .bg-maroon button {
    color: $black !important;
    background: $gold;
    &:hover {
        background: lighten($gold, 10%) !important;
    }
}